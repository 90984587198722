import React from 'react';
import {
  Container,
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
  Divider,
  Link,
} from '@mui/material';
import './styles.css';

const PrivacyPolicy = () => {
  return (
    <div className='privacyBody'>
      <Container maxWidth='md'>
        <Paper elevation={3} className='privacyContainer'>
          <Box p={{ xs: 4, sm: 6, md: 8 }}>
            <Typography variant='h3' gutterBottom className='privacyTitle'>
              Privacy Policy
            </Typography>
            <Typography
              variant='subtitle1'
              gutterBottom
              className='privacySubtitle'
            >
              Effective Date: 16/07/2024
            </Typography>
            <Divider />
            <Typography
              variant='h5'
              gutterBottom
              className='privacySectionTitle'
            >
              1. Introduction
            </Typography>
            <Typography paragraph className='privacyParagraph'>
              Welcome to Eronware. We are committed to protecting your privacy
              and ensuring the security of your personal information. This
              Privacy Policy explains how we collect, use, disclose, and
              safeguard your information when you visit our website{' '}
              <Link href='https://eronware.com'>Eronware</Link>, use our
              services, and register with your email address.
            </Typography>
            <Divider />
            <Typography
              variant='h5'
              gutterBottom
              className='privacySectionTitle'
            >
              2. Information We Collect
            </Typography>
            <Typography paragraph className='privacyParagraph'>
              We collect the following types of information:
            </Typography>
            <List className='privacyList'>
              <ListItem className='privacyListItem'>
                <ListItemText
                  primary='Personal Information'
                  secondary='When you register with your email address, we collect your email address and any other information you provide voluntarily.'
                />
              </ListItem>
              <ListItem className='privacyListItem'>
                <ListItemText
                  primary='Usage Data'
                  secondary='We may collect information about your interactions with our website, such as your IP address, browser type, pages visited, and time spent on each page.'
                />
              </ListItem>
              <ListItem className='privacyListItem'>
                <ListItemText
                  primary='Cookies and Tracking Technologies'
                  secondary='We use cookies and similar tracking technologies to enhance your experience on our website and gather data about your preferences.'
                />
              </ListItem>
            </List>
            <Divider />
            <Typography
              variant='h5'
              gutterBottom
              className='privacySectionTitle'
            >
              3. How We Use Your Information
            </Typography>
            <Typography paragraph className='privacyParagraph'>
              We use the information we collect for various purposes, including:
            </Typography>
            <List className='privacyList'>
              <ListItem className='privacyListItem'>
                <ListItemText primary='To provide and maintain our services' />
              </ListItem>
              <ListItem className='privacyListItem'>
                <ListItemText primary='To manage your registration and account' />
              </ListItem>
              <ListItem className='privacyListItem'>
                <ListItemText primary='To communicate with you, including sending you updates, notifications, and promotional materials' />
              </ListItem>
              <ListItem className='privacyListItem'>
                <ListItemText primary='To analyze usage and improve our services' />
              </ListItem>
              <ListItem className='privacyListItem'>
                <ListItemText primary='To comply with legal obligations' />
              </ListItem>
            </List>
            <Divider />
            <Typography
              variant='h5'
              gutterBottom
              className='privacySectionTitle'
            >
              4. Sharing Your Information
            </Typography>
            <Typography paragraph className='privacyParagraph'>
              We do not sell, trade, or rent your personal information to
              others. We may share your information in the following situations:
            </Typography>
            <List className='privacyList'>
              <ListItem className='privacyListItem'>
                <ListItemText
                  primary='Service Providers'
                  secondary='We may share your information with third-party service providers who perform services on our behalf, such as email marketing and customer support.'
                />
              </ListItem>
              <ListItem className='privacyListItem'>
                <ListItemText
                  primary='Legal Requirements'
                  secondary='We may disclose your information if required to do so by law or in response to valid requests by public authorities.'
                />
              </ListItem>
            </List>
            <Divider />
            <Typography
              variant='h5'
              gutterBottom
              className='privacySectionTitle'
            >
              5. Data Security
            </Typography>
            <Typography paragraph className='privacyParagraph'>
              We implement appropriate technical and organizational measures to
              protect your personal information from unauthorized access, use,
              or disclosure. However, no method of transmission over the
              internet or electronic storage is completely secure.
            </Typography>
            <Divider />
            <Typography
              variant='h5'
              gutterBottom
              className='privacySectionTitle'
            >
              6. Your Data Protection Rights
            </Typography>
            <Typography paragraph className='privacyParagraph'>
              Depending on your location, you may have the following rights
              regarding your personal information:
            </Typography>
            <List className='privacyList'>
              <ListItem className='privacyListItem'>
                <ListItemText primary='The right to access and receive a copy of your personal data' />
              </ListItem>
              <ListItem className='privacyListItem'>
                <ListItemText primary='The right to rectification of inaccurate or incomplete data' />
              </ListItem>
              <ListItem className='privacyListItem'>
                <ListItemText primary='The right to erasure of your personal data' />
              </ListItem>
              <ListItem className='privacyListItem'>
                <ListItemText primary='The right to restrict or object to processing of your data' />
              </ListItem>
              <ListItem className='privacyListItem'>
                <ListItemText primary='The right to data portability' />
              </ListItem>
            </List>
            <Typography paragraph className='privacyParagraph'>
              To exercise any of these rights, please contact us at
              support@eronware.com.
            </Typography>
            <Divider />
            <Typography
              variant='h5'
              gutterBottom
              className='privacySectionTitle'
            >
              7. Third-Party Links
            </Typography>
            <Typography paragraph className='privacyParagraph'>
              Our website may contain links to third-party websites. We are not
              responsible for the privacy practices or content of those
              websites. We encourage you to review the privacy policies of any
              third-party sites you visit.
            </Typography>
            <Divider />
            <Typography
              variant='h5'
              gutterBottom
              className='privacySectionTitle'
            >
              8. Changes to This Privacy Policy
            </Typography>
            <Typography paragraph className='privacyParagraph'>
              We may update this Privacy Policy from time to time. We will
              notify you of any changes by posting the new Privacy Policy on
              this page and updating the effective date. Your continued use of
              our services after such changes constitutes your acceptance of the
              new Privacy Policy.
            </Typography>
            <Divider />
            <Typography
              variant='h5'
              gutterBottom
              className='privacySectionTitle'
            >
              9. Contact Us
            </Typography>
            <Typography paragraph className='privacyParagraph'>
              If you have any questions or concerns about this Privacy Policy or
              our data practices, please contact us at:
            </Typography>
            <Typography paragraph className='privacyParagraph'>
              <li>
                {' '}
                <a href='mailto:support@eronware.com'>support@eronware.com</a>
              </li>
            </Typography>
          </Box>
        </Paper>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
