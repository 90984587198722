import React, { useState } from 'react';
import {
  TextField,
  MenuItem,
  Button,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material';
import axios from 'axios';
import CountUp from 'react-countup';
import { styled } from '@mui/system';
import Cookies from 'universal-cookie';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setToken,
  setRefresh,
  initializeAuth,
} from '../../features/auth/authSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import env from 'react-dotenv';

const FlagMenuItem = styled(MenuItem)({
  display: 'flex',
  alignItems: 'center',
});

const FlagIcon = styled('img')({
  width: 24,
  height: 16,
  marginRight: 8,
});

const categoriesByMarketplace = {
  US: [
    'Appliances',
    'Arts, Crafts & Sewing',
    'Automotive',
    'Baby',
    'Beauty & Personal Care',
    'Camera & Photo',
    'Cell Phones & Accessories',
    'Clothing, Shoes & Jewelry',
    'Computers & Accessories',
    'Electronics',
    'Grocery & Gourmet Food',
    'Health & Household',
    'Home & Kitchen',
    'Industrial & Scientific',
    'Kitchen & Dining',
    'Musical Instruments',
    'Office Products',
    'Patio, Lawn & Garden',
    'Pet Supplies',
    'Sports & Outdoors',
    'Tools & Home Improvement',
    'Toys & Games',
  ],
  CA: [
    'Automotive',
    'Baby',
    'Beauty & Personal Care',
    'Clothing & Accessories',
    'Electronics',
    'Grocery & Gourmet Food',
    'Health & Personal Care',
    'Home',
    'Industrial & Scientific',
    'Jewelry',
    'Luggage & Bags',
    'Musical Instruments, Stage & Studio',
    'Office Products',
    'Patio, Lawn & Garden',
    'Pet Supplies',
    'Shoes & Handbags',
    'Sports & Outdoors',
    'Tools & Home Improvement',
    'Toys & Games',
    'Watches',
  ],
  MX: [
    'Bebé',
    'Deportes y Aire Libre',
    'Electrónicos',
    'Herramientas y Mejoras del Hogar',
    'Hogar y Cocina',
    'Industria, Empresas y Ciencia',
    'Instrumentos Musicales',
    'Juguetes y Juegos',
    'Oficina y papelería',
    'Ropa, Zapatos y Accesorios',
    'Salud y Cuidado Personal',
  ],
};

const marketplaceDomains = {
  US: 'com',
  CA: 'ca',
  MX: 'com.mx',
};

const backendURL = env.API_URL;

const EstimatedSales = () => {
  const [marketplace, setMarketplace] = useState('');
  const [category, setCategory] = useState('');
  const [rank, setRank] = useState('');
  const [sales, setSales] = useState(null);
  const [loading, setLoading] = useState(false);
  const { token, refresh } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get('token');
    const refresh = cookies.get('refresh');

    if (token) {
      dispatch(setToken(token));
    }

    if (refresh) {
      dispatch(setRefresh(refresh));
    }

    dispatch(initializeAuth());
  }, [token, refresh, dispatch]);

  const handleMarketplaceChange = (event) => {
    setMarketplace(event.target.value);
    setCategory(''); // Reset category when marketplace changes
    setRank(''); // Reset rank when marketplace changes
  };

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
    setRank(''); // Reset rank when category changes
  };

  const handleRankChange = (event) => {
    setRank(event.target.value);
  };

  const refreshToken = async () => {
    const cookies = new Cookies();
    const refresh = cookies.get('refresh');

    try {
      const response = await axios.post(`${backendURL}/auth/refresh`, {
        refresh_token: refresh,
      });
      const newToken = response.data.data.token;
      const newRefresh = response.data.data.refresh;
      cookies.set('token', newToken);
      cookies.set('refresh', newRefresh);
      dispatch(setToken(newToken));
      return newToken;
    } catch (error) {
      console.error('Token refresh failed:', error);
      return null;
    }
  };

  const handleCalculate = async () => {
    setLoading(true);
    const cookies = new Cookies();
    let token = cookies.get('token');
    const domain = marketplaceDomains[marketplace];
    try {
      const response = await axios.get(
        `${backendURL}/arbitrage/estimated-sales`,
        {
          params: {
            marketplace: domain,
            category,
            salesRank: rank,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSales(response.data.data.sales);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        token = await refreshToken();
        if (token) {
          // Retry the request with the new token
          try {
            const retryResponse = await axios.get(
              `${backendURL}/arbitrage/estimated-sales`,
              {
                params: {
                  marketplace: domain,
                  category,
                  salesRank: rank,
                },
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            setSales(retryResponse.data.data.sales);
          } catch (retryError) {
            console.error('Retry failed:', retryError);
          }
        }
      } else if (error.response.status === 429) {
        toast.error('You have reached your daily limit of 5 queries.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        console.error('Request failed:', error);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant='h4' gutterBottom>
        Estimated Sales Calculator
      </Typography>
      <Box sx={{ mb: 2 }}>
        <TextField
          select
          label='Marketplace'
          value={marketplace}
          onChange={handleMarketplaceChange}
          fullWidth
          margin='normal'
        >
          <FlagMenuItem value='US'>
            <FlagIcon
              src='https://d1uznvntk80v7s.cloudfront.net/flags/us.svg'
              alt='US Flag'
            />
            United States
          </FlagMenuItem>
          <FlagMenuItem value='CA'>
            <FlagIcon
              src='https://d1uznvntk80v7s.cloudfront.net/flags/ca.svg'
              alt='Canada Flag'
            />
            Canada
          </FlagMenuItem>
          <FlagMenuItem value='MX'>
            <FlagIcon
              src='https://d1uznvntk80v7s.cloudfront.net/flags/mx.svg'
              alt='Mexico Flag'
            />
            Mexico
          </FlagMenuItem>
        </TextField>
        <TextField
          select
          label='Category'
          value={category}
          onChange={handleCategoryChange}
          fullWidth
          margin='normal'
          disabled={!marketplace} // Disable if no marketplace selected
        >
          {categoriesByMarketplace[marketplace]?.map((cat) => (
            <MenuItem key={cat} value={cat}>
              {cat}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label='Rank'
          value={rank}
          onChange={handleRankChange}
          type='number'
          fullWidth
          margin='normal'
          disabled={!category} // Disable if no category selected
        />
      </Box>
      <Button
        variant='contained'
        color='primary'
        onClick={handleCalculate}
        disabled={!marketplace || !category || !rank || loading} // Disable if any field is empty
      >
        {loading ? <CircularProgress size={24} color='inherit' /> : 'Calculate'}
      </Button>
      {sales !== null && (
        <Box sx={{ mt: 4 }}>
          <Typography variant='h5' gutterBottom>
            Estimated Sales per Month:
          </Typography>
          <Typography variant='h3' color='#52b202'>
            <CountUp start={0} end={sales} duration={2.75} separator=',' />
          </Typography>
        </Box>
      )}
      <ToastContainer />
    </Box>
  );
};

export default EstimatedSales;
