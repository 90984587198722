import React from 'react';
import {
  Typography,
  Container,
  Grid,
  Paper,
  Box,
  CardContent,
  CardMedia,
  Link,
  Button,
} from '@mui/material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import backgroundImg from '../../media/site-background.webp';
import './styles.css';

const Home = () => {
  return (
    <div>
      <Box
        className='hero'
        sx={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundImg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: '#fff',
          py: { xs: 6, md: 8 },
          textAlign: 'center',
          position: 'relative',
          boxShadow: 'inset 0 0 0 2000px rgba(0, 0, 0, 0.2)',
        }}
      >
        <Container maxWidth='md'>
          <Typography variant='h2' component='h1' gutterBottom>
            Empower Your Amazon Business
          </Typography>
          <Typography variant='h5' paragraph>
            Tools and Insights to Grow Your Sales
          </Typography>
        </Container>
      </Box>

      <Container
        maxWidth='lg'
        className='features'
        sx={{ py: { xs: 6, md: 8 } }}
      >
        <Typography variant='h4' align='center' gutterBottom>
          Features
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} className='card'>
              <CardMedia
                className='cardMedia'
                component={AssessmentIcon}
                sx={{ fontSize: '4rem', color: 'primary.main', my: 2 }}
              />
              <CardContent className='cardContent'>
                <Typography gutterBottom variant='h5'>
                  Arbitrage Extension
                </Typography>
                <Typography>
                  Find profitable products to sell on Amazon with our extension.
                </Typography>
                <Button
                  variant='contained'
                  color='primary'
                  href='https://chromewebstore.google.com/detail/eronware-amazon-arbitrage/ehmfdpppjjaibmabjoinbfmlnncjahkh'
                  target='_blank'
                  sx={{
                    mt: 2,
                    borderRadius: '20px',
                    padding: '10px 20px',
                    fontWeight: 'bold',
                    fontSize: '1.1rem',
                  }}
                  className='downloadButton'
                >
                  Download
                </Button>
              </CardContent>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Paper elevation={3} className='card'>
              <CardMedia
                className='cardMedia'
                component={SupportAgentIcon}
                sx={{ fontSize: '4rem', color: 'primary.main', my: 2 }}
              />
              <CardContent className='cardContent'>
                <Typography gutterBottom variant='h5'>
                  Customer Support
                </Typography>
                <Typography>
                  Responsive support to help you with any issues or questions.
                </Typography>
                <Typography variant='h6' sx={{ mt: 2 }}>
                  <Link
                    href='mailto:support@eronware.com'
                    underline='hover'
                    color='primary'
                    sx={{ fontWeight: 'bold', fontSize: '1.2rem' }}
                    className='supportEmail'
                  >
                    support@eronware.com
                  </Link>
                </Typography>
              </CardContent>
            </Paper>
          </Grid>
        </Grid>
      </Container>

      <Box className='footer' sx={{ py: 4, backgroundColor: '#f8f8f8' }}>
        <Container maxWidth='md'>
          <Typography variant='h6' align='center' gutterBottom>
            Amazon Seller Solutions
          </Typography>
          <Typography variant='subtitle1' align='center' color='textSecondary'>
            Empowering Your Amazon Business to Reach New Heights
          </Typography>
          <Typography variant='subtitle1' align='center' color='textSecondary'>
            © {new Date().getFullYear()} Eronware. All rights reserved.
          </Typography>
          <Typography variant='subtitle1' align='center'>
            <Link
              href='https://eronware.com/privacy-policy'
              underline='hover'
              color='inherit'
            >
              Privacy Policy
            </Link>
          </Typography>
        </Container>
      </Box>
    </div>
  );
};

export default Home;
