import './App.css';
import SignIn from './screens/login/SignIn';
import SignUp from './screens/signup/SignUp';
import Home from './screens/home/Home';
import ForgotPassword from './screens/forgot-password/ForgotPassword';
import ResetPassword from './screens/reset-password/ResetPassword';
import PageNotFound from './screens/page-not-found/PageNotFound';
import Blog from './screens/blog/Blog';
import Pricing from './screens/pricing/Pricing';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  setToken,
  setRefresh,
  initializeAuth,
} from './features/auth/authSlice';
import Cookies from 'universal-cookie';
import NavBar from './components/navBar/Navbar';
import PrivacyPolicy from './screens/privacy-policy/PrivacyPolicy';
import EstimatedSales from './screens/estimated-sales/EstimatedSales';
import { checkRefreshToken } from './features/auth/authActions';
import { logout } from './features/auth/authSlice';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const { token, refresh } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [isRefreshTokenValid, setIsRefreshTokenValid] = useState(false);

  useEffect(() => {
    const checkTokenValidity = async () => {
      const cookies = new Cookies();
      const token = cookies.get('token');
      const refresh = cookies.get('refresh');
      let result;
      try {
        if (token && refresh) {
          result = await dispatch(checkRefreshToken({ refreshToken: refresh }));
          setIsRefreshTokenValid(result.payload);

          console.log(result.payload);
          console.log(token);
          console.log(refresh);

          if (result.payload && result.payload.status) {
            // Eğer backend "status" döndürüyorsa
            if (token) {
              dispatch(setToken(token));
            }
            if (refresh) {
              dispatch(setRefresh(refresh));
            }
            dispatch(initializeAuth());
          } else {
            dispatch(logout());
            navigate('/login');
          }
        }
      } catch (error) {
        setIsRefreshTokenValid(false);
      }
    };

    checkTokenValidity();
    const publicRoutes = [
      '/login',
      '/register',
      '/forgot-password',
      '/reset-password',
      '/privacy-policy',
    ];

    if (!token && !refresh && !publicRoutes.includes(location.pathname)) {
      navigate('/login');
    }
  }, [navigate, token, refresh, location.pathname]);

  return (
    <div>
      {token && refresh && <NavBar />}

      <main className='container content'>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/register' element={<SignUp />} />
          <Route path='/login' element={<SignIn />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/pricing' element={<Pricing />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          {/* <Route path='/estimated-sales' element={<EstimatedSales />} /> */}
          <Route path='/*' element={<PageNotFound />} />
        </Routes>
      </main>
    </div>
  );
}

export default App;
