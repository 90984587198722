import React from 'react';
import {
  Container,
  Typography,
  List,
  ListItem,
  ListItemText,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Box,
  IconButton,
  Tooltip,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './styles.css';

const theme = createTheme({
  palette: {
    primary: {
      main: '#003366',
    },
    secondary: {
      main: '#555555',
    },
    background: {
      default: '#f4f6f8',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h4: {
      fontWeight: 700,
      fontSize: '2.5rem',
      color: '#003366',
    },
    body1: {
      fontSize: '1rem',
      color: '#333333',
      lineHeight: 1.6,
    },
  },
});

const tips = [
  {
    primary: 'Research Your Products Thoroughly',
    secondary:
      'Ensure there is demand for the products you want to sell. Use our own Eronware Amazon Arbitrage extension to gauge market trends and competition.',
  },
  {
    primary: 'Understand Amazon SEO',
    secondary:
      'Master the intricacies of Amazon’s search algorithm to make your products more visible. Keywords are crucial but place them wisely to maintain readability and customer engagement.',
  },
  {
    primary: 'Use High-Quality Photos',
    secondary:
      'Invest in professional photography to make your listings stand out. Images are often the first impression customers get, so make them count.',
  },
  {
    primary: 'Consider Seasonal Trends',
    secondary:
      'Adjust your inventory and marketing strategies based on seasonal demands. This can significantly boost your sales during peak times.',
  },
  {
    primary: 'Engage on Social Media',
    secondary:
      'Create social media campaigns that lead traffic to your Amazon store. This not only increases sales but also enhances customer loyalty.',
  },
  {
    primary: 'Implement Dynamic Pricing',
    secondary:
      'Use pricing tools to automatically adjust your prices based on market conditions and competitor pricing.',
  },
  {
    primary: 'Cultivate an Amazon Brand Store',
    secondary:
      'Develop a brand store within Amazon to showcase your products and increase customer trust through brand consistency.',
  },
  {
    primary: 'Focus on Sustainability',
    secondary:
      'Highlight sustainable practices and eco-friendly products. This not only appeals to a growing demographic but also builds a positive brand image.',
  },
  {
    primary: 'Focus on Customer Service',
    secondary:
      'Provide excellent customer service to boost your seller ratings. Respond promptly to customer inquiries and address any issues effectively.',
  },
  {
    primary: 'Utilize Amazon FBA',
    secondary:
      'Fulfillment by Amazon (FBA) can help streamline your shipping process and improve customer satisfaction. Take advantage of FBA to handle storage, packing, and shipping.',
  },
];

function Blog() {
  return (
    <ThemeProvider theme={theme}>
      <Box className='blog-root'>
        <Box className='blog-hero'>
          <Container>
            <Typography variant='h4' className='blog-heroText' color='inherit'>
              Welcome to Eronware's Amazon Blog
            </Typography>
          </Container>
        </Box>
        <Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card className='intro' elevation={3}>
                <CardHeader title='Introduction' className='blog-cardHeader' />
                <CardContent>
                  <Typography variant='body1' paragraph>
                    Hi there! We are the developers behind Eronware. With years
                    of experience in e-commerce and Amazon selling, we’ve
                    created this blog to share valuable insights, tips, and
                    strategies to help you succeed as an Amazon seller. Whether
                    you’re a beginner or a seasoned seller, there’s something
                    here for everyone.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card className='blog-tips' elevation={3}>
                <CardHeader
                  title='Top Amazon Seller Tips'
                  className='blog-cardHeader'
                />
                <CardContent>
                  <List>
                    {tips.map((tip, index) => (
                      <ListItem key={index} className='blog-listItem'>
                        <Tooltip title={tip.secondary} placement='right' arrow>
                          <IconButton edge='start' className='blog-listIcon'>
                            <CheckCircleIcon />
                          </IconButton>
                        </Tooltip>
                        <ListItemText
                          primary={tip.primary}
                          secondary={tip.secondary}
                        />
                      </ListItem>
                    ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
}

export default Blog;
