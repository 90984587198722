import React from 'react';
import { Grid, Button, Typography } from '@mui/material';
import monster from '../../media/godzilla.gif';
import './styles.css';

const PageNotFound = () => {
	return (
		<Grid container className='fullSize' direction='column' justify='center' alignItems='center' id='ERR404'>
			<Typography variant='h3'>OH NO!</Typography>
			<Typography variant='h5' align='center'>
				Godzilla just ate this page!
			</Typography>
			<Typography variant='h5' align='center' marginTop='20px'>
				Go back
				<Button className='textLink' href='/'>
					home
				</Button>
				before it's too late!
			</Typography>
			<img id='monsterImg' src={monster} alt='Godzilla' />
		</Grid>
	);
};

export default PageNotFound;
