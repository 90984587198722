import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import env from 'react-dotenv';

const backendURL = env.API_URL;

export const userLogin = createAsyncThunk('auth/login', async ({ email, password }, { rejectWithValue }) => {
	try {
		// configure header's Content-Type as JSON
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};
		const { data } = await axios.post(`${backendURL}/auth/login`, { email, password }, config);

		return data;
	} catch (error) {
		// return custom error message from API if any
		if (error.response && error.response.data.message) {
			return rejectWithValue(error.response.data.message);
		} else {
			return rejectWithValue(error.message);
		}
	}
});

export const registerUser = createAsyncThunk('auth/register', async ({ email, password }, { rejectWithValue }) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};

		await axios.post(`${backendURL}/auth/register`, { email, password }, config);
	} catch (error) {
		if (error.response && error.response.data.message) {
			return rejectWithValue(error.response.data.message);
		} else {
			return rejectWithValue(error.message);
		}
	}
});

export const forgotPassword = createAsyncThunk('auth/send-reset-password-email', async ({ email }, { rejectWithValue }) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};

		await axios.post(`${backendURL}/auth/send-reset-password-email`, { email }, config);
	} catch (error) {
		if (error.response && error.response.data.message) {
			return rejectWithValue(error.response.data.message);
		} else {
			return rejectWithValue(error.message);
		}
	}
});

export const resetPassword = createAsyncThunk('auth/reset-password', async ({ password }, { rejectWithValue }) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};

		await axios.post(`${backendURL}/auth/reset-password`, { password }, config);
	} catch (error) {
		if (error.response && error.response.data.message) {
			return rejectWithValue(error.response.data.message);
		} else {
			return rejectWithValue(error.message);
		}
	}
});

export const logoutAction = createAsyncThunk('auth/logout', async ({ refreshToken }, { rejectWithValue }) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};
		const { data } = await axios.post(`${backendURL}/auth/logout`, { refreshToken }, config);

		return data;
	} catch (error) {
		if (error.response && error.response.data.message) {
			return rejectWithValue(error.response.data.message);
		} else {
			return rejectWithValue(error.message);
		}
	}
});

export const checkRefreshToken = createAsyncThunk('auth/check-refresh-token', async ({ refreshToken }, { rejectWithValue }) => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};
		const { data } = await axios.post(`${backendURL}/auth/check-refresh-token`, { refreshToken }, config);

		return data;
	} catch (error) {
		if (error.response && error.response.data.message) {
			return rejectWithValue(error.response.data.message);
		} else {
			return rejectWithValue(error.message);
		}
	}
});
